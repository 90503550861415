import About_us from "views/pages/AboutUs/about_us";
import artworks from "views/pages/artwork/artworks";
// import contact_us from "views/pages/Contact_Us/contact_us";
import content from "views/pages/content/content";
import Content from "views/pages/content/content";
import Size from "views/pages/content/size";
import faqs from "views/pages/FAQ'S/faqs";
import Gallery from "views/pages/Gallery/gallery";
import PrintingCategories from "views/pages/content/printingCategories";
import News from "views/pages/News/news";
import contact from "views/pages/Contact_Us/contact";
import newsdetail from "views/pages/News/newsdetail";
import NewsBySlug from "views/pages/News/NewsBySlug";
import contentTest from "views/pages/content/contentTest";
import DetailTest from "views/pages/content/DetailTest";
import HowToOrder from "views/pages/HowToOrder/HowToOrder";
import WhyChooseGreen from "views/pages/WhyChooseGreen/WhyChooseGreen";

const dashboardRoutes = [
  // { path: "/", component: DashboardLayout },
  { path: "/", component: contentTest },
  { path: "/categories/size", component: Size },
  { path: "/categories/:slug", component: contentTest },
  { path: "/printing-products", component: PrintingCategories },
  { path: "/printing-products/:slug", component: contentTest },

  { path: "/categories/:slug/:brand", component: contentTest },
  { path: "/categories/:slug/product/:name", component: DetailTest },
  { path: "/printing-products/:slug/product/:name", component: DetailTest },
  { path: "/printing-products/:slug/:brand", component: contentTest },

  { path: "/news/", component: News },
  { path: "/news/:slug", component: NewsBySlug },
  { path: "/about/", component: About_us },
  { path: "/how-to-order/", component: HowToOrder },
  { path: "/why-choose-green/", component: WhyChooseGreen },

  { path: "/our-gallery/", component: Gallery },
  { path: "/contact/", component: contact },
  { path: "/artworks/", component: artworks },
  { path: "/faq/", component: faqs }
];

export default dashboardRoutes;
