import { api, handleResponse, handleError } from "./apiServices";

export const getProductQuantitiesTPA = data =>
  api()
    .post("/tpa/Quantity_sku", data)
    .then(handleResponse)
    .catch(handleError);

export const getMagSmsServer = data =>
  api()
    .post("/tpa/mag_sms_server", data)
    .then(handleResponse)
    .catch(handleError);
// export const getMagSmsServer = (data) =>
//   api()
//     .post("/tpa/mag_sms_server_nz.asp", data)
//     .then(handleResponse)
//     .catch(handleError);

export const getPrintSmsServer = data =>
  api()
    .post("/tpa/print_sms_server", data)
    .then(handleResponse)
    .catch(handleError);
