import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import "../../assets/css/dashboardlayout.css";
import { getsetting } from "services/settingservices";
import { useDispatch } from "react-redux";
import settingAction from "redux/settingdata/actions";
import mobilelogo from "../../assets/siteImages/mobileLogo.png";
import mobileSidelogo from "../../assets/siteImages/greenmagnets-nz-header.png";

const { settingdata } = settingAction;
const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

function HeaderUser(props) {
  const { token, success, error } = props;

  const [setting, setSetting] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const getData = async () => {
    await getsetting(token).then(data => {
      if (data.success) {
        setSetting(data.data[0]);
        console.log("sdadadawdad", data.data);
        dispatch(settingdata(data.data[0]));
        success();
      } else {
        error();
      }
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="sticky-nav">
        <div className="row logo-row" onClick={() => props.history.push("/")}>
          {/* <a> */}
          {/* greenmagnets-nz-header.png */}
          <img
            src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${setting?.logo}`}
            alt=""
            className="main-logo logomobile d-lg-none"
            style={{ cursor: "pointer" }}
          />

          <img
            src={`${mobileSidelogo}`}
            alt=""
            className="main-logo d-none d-lg-block"
            style={{ cursor: "pointer" }}
          />

          {/* </a> */}
        </div>
        <div className="row main-menu">
          <div className="col-md-10 col-10 mx-auto">
            <div className="row align-items-start">
              <div className="col-auto mobile_icon p-0">
                <div className="">
                  {/* <a
                    href="http://localhost:3000/fridge_magnets/"
                    style={{ color: "black" }}
                  > */}
                  <Link style={{ color: "black" }} to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </div>
                <div className="navbar-light ml-0">
                  <button
                    className="navbar-toggler border-0 my-2"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="">
                      <i class="fa fa-bars"></i>
                    </span>
                  </button>
                </div>
              </div>
              {/* <div className="col-sm-6 col-9 mobile_logo">
                <div className="">
                  <img
                    // src="	https://wholesale-magnets.com.au/wp-content/uploads/2020/08/11.png"
                    src={mobilelogo}
                    alt="Logo"
                    className=""
                    width="100%"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div> */}

              <div className="col-12 pl-0">
                <nav className="navbar navbar-expand-lg navbar-light">
                  {/* <a className='navbar-brand' href='#'>
                Navbar
              </a> */}

                  <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                      <li className="nav-item ml-lg-2">
                        <Link
                          className={
                            history.location.pathname === "/"
                              ? "nav-link is-active"
                              : "nav-link"
                          }
                          to=""
                        >
                          HOME
                        </Link>
                      </li>
                      <li className="nav-item ml-lg-2">
                        <Link
                          className={
                            history.location.pathname === "/"
                              ? "nav-link is-active"
                              : "nav-link"
                          }
                          to="/"
                        >
                          FRIDGE MAGNETS
                        </Link>
                      </li>
                      <li className="nav-item ml-lg-2">
                        {/* <Link
                          className={
                            history.location.pathname.includes("our-gallery")
                              ? "nav-link is-active"
                              : "nav-link"
                          }
                          to="/our-gallery"
                        >
                          GALLERY
                        </Link> */}
                        <a
                          className={
                            history.location.pathname.includes("our-gallery")
                              ? "nav-link is-active"
                              : "nav-link"
                          }
                          href="https://www.flipsnack.com/56BBDF99E8C/greenmagnets-nz-brochure/full-view.html"
                          target="_blank"
                        >
                          GALLERY
                        </a>{" "}
                      </li>
                      {/* <li className="nav-item ml-lg-2">
                        <Link
                          className={
                            history.location.pathname.includes("news")
                              ? "nav-link is-active"
                              : "nav-link"
                          }
                          to="/news"
                        >
                          NEWS
                        </Link>
                      </li> */}
                      <li className="nav-item ml-lg-2">
                        <Link
                          className={
                            history.location.pathname.includes("about-us")
                              ? "nav-link is-active"
                              : "nav-link"
                          }
                          to="/about"
                        >
                          ABOUT US
                        </Link>
                      </li>
                      <li className="nav-item ml-lg-2">
                        <Link
                          className={
                            history.location.pathname.includes("how-to-order")
                              ? "nav-link is-active"
                              : "nav-link"
                          }
                          to="/how-to-order"
                        >
                          HOW TO ORDER
                        </Link>
                      </li>{" "}
                      <li className="nav-item ml-lg-2">
                        <Link
                          className={
                            history.location.pathname.includes(
                              "why-choose-green"
                            )
                              ? "nav-link is-active"
                              : "nav-link"
                          }
                          to="/why-choose-green"
                        >
                          WHY CHOOSE GREEN
                        </Link>
                      </li>
                      <li className="nav-item ml-lg-2">
                        <Link
                          className={
                            history.location.pathname.includes("contact")
                              ? "nav-link is-active"
                              : "nav-link"
                          }
                          to="/contact"
                        >
                          CONTACT US
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching,
    image: state.productimage.image_src
  };
};

export default compose(
  withRouter,
  // enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(HeaderUser);
