import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  getCategories,
  getCategoryByName,
  getFridgeMagnetProductsOnly,
  getPrintingProductsOnly
} from "services/categoryServices";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import {
  getBrandByProduct,
  getBrandByProduct1,
  getSlugByProduct,
  getSlugByProduct1
} from "services/productServices";
import { addIPAdd, updateIPAdd } from "services/IPAddServices";
import InquiryActions from "redux/InquiryData/actions";
import productimageAction from "redux/productimage/actions";
import currentRouteAction from "redux/currentRoute/action";
import axios from "axios";
import { Helmet } from "react-helmet";

const { success, error, fetching, scroll } = NavigationActions;
const { setAllData, setIpData, setPData, setStep } = InquiryActions;
const { image, resetImage } = productimageAction;
const { currentRoute } = currentRouteAction;

function Content(props) {
  const { token, success, error, scroll, inquiry } = props;
  const { step, pData, ipData, allData } = inquiry;
  const dispatch = useDispatch();
  let { slug, brand } = useParams();
  const newRef = useRef();
  const history = useHistory();
  const location = useLocation();

  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState("");

  const [productBrand, setProductBrand] = useState([]);
  const [alldata, setAlldata] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [IPData, setIPData] = useState({
    IP_Address: "",
    completedStep: 0,
    stepName: "",
    product_id: ""
  });

  useEffect(() => {
    dispatch(resetImage());
    if (location.pathname === "/") {
      dispatch(
        currentRoute({ currentRoute: "/", isCurrentLocationPrinting: false })
      );
    }
  }, [location?.pathname, dispatch]);

  let checkLoaction = location.pathname.includes("printing-products");

  //creating function to load ip address from the API
  const getIPData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    dispatch(setIpData({ ...ipData, IP_Address: res.data.IPv4 }));
  };

  const addIPData = async () => {
    await addIPAdd(ipData).then(data => {
      if (data.success) {
        success(data.message);
      } else {
        error(data.message);
      }
    });
  };

  const getCategoryBySlug = async seoSlug => {
    setCategory("");
    await getCategoryByName(token, seoSlug).then(res => {
      if (res.success) {
        setCategory(res?.data[0]);
      }
    });
  };

  const UpdateIPData = async () => {
    await updateIPAdd(ipData).then(data => {
      if (data.success) {
        success(data.message);
      } else {
        error(data.message);
      }
    });
  };

  const getData = async () => {
    if (!history.location.pathname.includes("/printing-products")) {
      await getFridgeMagnetProductsOnly(token).then(res => {
        if (res.success) {
          setMenuData(res.data);
        } else {
          error(res.message);
        }
      });
    } else {
      await getPrintingProductsOnly(token).then(res => {
        console.log("res", res);
        if (res.success) {
          setMenuData(res.data);
        } else {
          error(res.message);
        }
      });
    }

    // await getCategories(token).then((data) => {
    //   if (!history.location.pathname.includes("/printing-products")) {
    //     console.log(data, "check84");
    //     const subcat = data.data?.filter((val) => {
    //       return val?.parent_id !== 0;
    //     });
    //     console.log(subcat, typeof subcat, subcat[0]?.slug, "sub cat check");

    //     sub_cat = subcat[0]?.slug;
    //   } else {
    //     const subcat = data.data?.filter((val) => {
    //       return val?.parent_id === 2;
    //     });
    //     console.log(
    //       "subcat check in printing products,data.data",
    //       subcat,
    //       data.data
    //     );
    //     sub_cat = subcat[0]?.slug;
    //   }

    //   if (data.success) {
    //     success();
    //   } else {
    //     error();
    //   }
    // });
    if (slug === "" || slug === undefined) {
      if (!history.location.pathname.includes("/printing-products")) {
        await getSlugByProduct(token).then(data => {
          if (data) {
            const arrayUniqueByKey = [
              ...new Map(data.data.map(item => [item["id"], item])).values()
            ];
            console.log("came here to find products ", arrayUniqueByKey);

            setProduct(arrayUniqueByKey);
          } else {
            error(data.message);
          }
        });
      } else {
        await getSlugByProduct1(token, slug).then(data => {
          if (data.success) {
            const arrayUniqueByKey = [
              ...new Map(
                data.data.map(item => [item["product_name"], item])
              ).values()
            ];
            setProduct(arrayUniqueByKey);
            // setProduct(data.data);
            success();
          } else {
            error();
          }
        });
      }
    } else {
      if (!history.location.pathname.includes("/printing-products")) {
        await getSlugByProduct(token, slug).then(data => {
          if (data.success) {
            console.log("data check 192", data);
            const arrayUniqueByKey = [
              ...new Map(
                data.data.map(item => [item["product_name"], item])
              ).values()
            ];
            setProduct(arrayUniqueByKey);
            success();
          } else {
            error();
          }
        });
        if (slug && brand) {
          await getBrandByProduct(token, slug, brand).then(data => {
            if (data.success) {
              setProductBrand(data.data);
              success();
            } else {
              error();
            }
          });
        }
      } else {
        await getSlugByProduct1(token, slug).then(data => {
          if (data.success) {
            const arrayUniqueByKey = [
              ...new Map(
                data.data.map(item => [item["product_name"], item])
              ).values()
            ];
            setProduct(arrayUniqueByKey);
            // setProduct(data.data);
            success();
          } else {
            error();
          }
        });
        if (slug && brand) {
          await getBrandByProduct1(token, slug, brand).then(data => {
            if (data.success) {
              setProductBrand(data.data);
              success();
            } else {
              error();
            }
          });
        }
      }
    }
  };

  // USEEFFECT

  useEffect(() => {
    getData();
    dispatch(setStep(1));
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    // step === 1 && dispatch(image());
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    scroll("scroll");

    getIPData();
    if (
      ipData?.IP_Address &&
      ipData.IP_Address !== "" &&
      ipData.completedStep === 1
    ) {
      addIPData();
    }
    // eslint-disable-next-line
  }, [step]);

  useEffect(() => {
    if (
      ipData?.IP_Address &&
      ipData.IP_Address !== "" &&
      ipData.completedStep > 1
    ) {
      UpdateIPData();
    }

    if (step === 1) {
      dispatch(setPData([]));
      if (step === 1 && !history.location.pathname.includes("/size")) {
        dispatch(setAllData([]));
      }
    }

    // eslint-disable-next-line
  }, [step, ipData.stepName]);

  useEffect(() => {
    if (slug) {
      let seoSlug = slug.replace(/-/g, " ");
      getCategoryBySlug(seoSlug);
    }
    dispatch(setIpData({ ...ipData, completedStep: 0 }));
    // eslint-disable-next-line
  }, [slug]);

  useEffect(() => {
    if (slug) {
      let seoSlug = slug.replace(/-/g, " ");
      getCategoryBySlug(seoSlug);
    }
  }, [slug]);

  if (history.location.pathname.includes("size")) {
    return <></>;
  }
  return (
    <div ref={newRef}>
      <Helmet>
        <title>
          {category
            ? category?.title
            : slug
            ? slug?.replace(/-/g, " ")
            : "Green Magnets NZ"}
        </title>
        <meta name="robots" content="max-image-preview:large" />
        <meta
          name="description"
          content={category ? category?.metaDescription : ""}
        ></meta>
        <meta name="keywords" content={category ? category?.seoKey : ""}></meta>
        <link rel="canonical" href={category ? category?.canonical : ""}></link>
      </Helmet>
      <>
        <div>
          {history.location.pathname.includes(`/${slug}`) &&
          !history.location.pathname.includes("/size") ? (
            <div className="isCatNameTag">
              {console.log("slug check", slug)}
              <h4 className="p-title">{slug?.replace(/-/g, " ")}</h4>
            </div>
          ) : null}

          <div className="title">
            <h3>Get An Instant Price By SMS Now</h3>
            <h4>
              What size{" "}
              {!history.location.pathname.includes("/printing-products")
                ? "fridge magnet"
                : "printing product"}{" "}
              are you looking for ? <span> Click Below</span>
            </h4>
          </div>
          {/* {console.log(
              "check 259",
              history.location.pathname,
              history.location.pathname.split("/").length,
              history.location.pathname.split("/"),
              history.location.pathname.split("/")[
                history.location.pathname.split("/").length - 1
              ],
              history.location.pathname.split("/")[
                history.location.pathname.split("/").length - 2
              ],
              history.location.pathname.split("/")[
                history.location.pathname.split("/").length - 3
              ]
            )} */}

          <div className="row mb-3 mycard">
            {history.location.pathname.split("/")[1] === "" ? (
              <>
                {product.map((val, ind) => {
                  console.log("product check 346", val);
                  return val.parent_category_name === "Fridge Magnets" ? (
                    <div
                      key={"a" + ind}
                      className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                    >
                      <div
                        className="card"
                        onClick={() => {
                          dispatch(
                            setIpData({
                              ...ipData,
                              completedStep: 0 + 1,
                              stepName: "Product Selected",
                              product_id: val.id,
                              category_id: val.category_id
                            })
                          );

                          dispatch(setStep(step + 1));
                          dispatch(
                            setPData({
                              size: val.size,
                              pname: val.product_name,
                              pquantity: val.product_quantity,
                              category: val.name,
                              description: val.description,
                              SKU: val.SKU
                            })
                          );
                          dispatch(
                            setAllData({
                              ...allData,
                              cant_find_your_size: "false",
                              category: val.name,
                              size: val.size
                            })
                          );

                          // dispatch({ type: "image" });
                          // dispatch(image(val?.product_image));
                          // dispatch(currentRoute(val.product_name));

                          dispatch(
                            image({
                              tabName: val.product_name,
                              isPrinting: checkLoaction || false,
                              image_src: val?.product_image
                            })
                          );

                          history.push(
                            `/categories/${val.name
                              .toLowerCase()
                              .trim()
                              .replace(/ /g, "_")}/product/${val.product_name
                              .trim()
                              .replace(/ /g, "_")
                              .replace(/\//g, "#")}`
                          );
                          // dispatch(brandimage(val.brandimg));
                        }}
                        style={{
                          cursor: "pointer"
                        }}
                      >
                        <div className="">
                          <img
                            className="card-img-top"
                            src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.product_image}`}
                            alt="Card cap"
                            width="100%"
                          />
                        </div>
                        <div className="prdsize">
                          <p>{val.size} </p>
                        </div>
                        <div className="card-body  text-center img_txt">
                          <h5 className="card-title">{val.product_name}</h5>
                          <h5>{val.parent_category_name}</h5>
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
                <div>
                  <p
                    style={{
                      marginLeft: "15px",
                      fontWeight: 600,
                      textTransform: "uppercase",
                      fontSize: "17px"
                    }}
                  >
                    CAN’T FIND YOUR SIZE ?
                    <Link
                      to="/categories/size"
                      style={{
                        marginLeft: "3px",
                        color: "#67AB49",
                        textDecoration: "underline"
                      }}
                    >
                      Click here
                    </Link>
                  </p>
                </div>
              </>
            ) : history.location.pathname.split("/")[
                history.location.pathname.split("/").length - 1
              ] === "printing-products" ? (
              <>
                {product.map((val, ind) => {
                  console.log(val, "check 388");
                  return val.parent_category_name === "Printing Products" ? (
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                      key={"b" + ind}
                    >
                      <div
                        className="card "
                        onClick={() => {
                          dispatch(
                            setIpData({
                              ...ipData,
                              stepName: "Product Selected",
                              completedStep: 0 + 1,
                              product_id: val.id,
                              category_id: val.category_id
                            })
                          );

                          val.name === "ENVELOPES"
                            ? dispatch(setStep(1 + 1.5))
                            : val.name === "FLYERS"
                            ? dispatch(setStep(1 + 1.6))
                            : dispatch(setStep(step + 1));
                          dispatch(
                            setPData({
                              size: val.size,
                              pname: val.product_name,
                              pquantity: val.product_quantity,
                              category: val.name,
                              description: val.description,
                              SKU: val.SKU
                            })
                          );
                          dispatch(
                            setAllData({
                              ...allData,
                              cant_find_your_size: "false",
                              category: val.name,
                              size: val.size
                            })
                          );

                          // dispatch({ type: "image" });
                          // dispatch(image(val?.product_image));
                          // dispatch(currentRoute(val.product_name));
                          dispatch(
                            image({
                              tabName: val.product_name,
                              image_src: val?.product_image,
                              isPrinting: checkLoaction || false
                            })
                          );
                          console.log("came here check 471");
                          history.push(
                            `/printing-products/${slug}/product/${val.product_name
                              .trim()
                              .replace(/ /g, "_")
                              .replace(/\//g, "#")}`
                          );
                        }}
                        style={{
                          cursor: "pointer"
                        }}
                      >
                        <div className="">
                          <img
                            className="card-img-top"
                            src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.product_image}`}
                            alt="Card cap"
                            width="100%"
                          />
                        </div>
                        <div className="prdsize">
                          <p>{val.size}</p>
                        </div>
                        <div className="card-body  text-center img_txt">
                          <h5 className="card-title">{val.product_name}</h5>
                          <h5>{val.parent_category_name}</h5>
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
                <div className="col-12 printingtip">
                  <Link to="/contact">Can't find what you're looking for?</Link>
                </div>
              </>
            ) : history.location.pathname.split("/")[
                history.location.pathname.split("/").length - 2
              ] === "/" ? (
              product.map((val, ind) => {
                return val.parent_category_name === "Fridge Magnets" ? (
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                    key={"c" + ind}
                  >
                    <div
                      className="card "
                      onClick={() => {
                        dispatch(
                          setIpData({
                            ...ipData,
                            stepName: "Product Selected",
                            completedStep: 0 + 1,
                            product_id: val.id,
                            category_id: val.category_id
                          })
                        );

                        val.name === "ENVELOPES"
                          ? dispatch(setStep(1 + 1.5))
                          : val.name === "FLYERS"
                          ? dispatch(setStep(1 + 1.6))
                          : dispatch(setStep(step + 1));

                        dispatch(
                          setPData({
                            size: val.size,
                            pname: val.product_name,
                            pquantity: val.product_quantity,
                            category: val.name,
                            description: val.description,
                            SKU: val.SKU
                          })
                        );
                        dispatch(
                          setAllData({
                            ...allData,
                            cant_find_your_size: "false",
                            category: val.name,
                            size: val.size
                          })
                        );

                        dispatch(
                          image({
                            tabName: val.product_name,
                            image_src: val?.product_image,
                            isPrinting: checkLoaction || false
                          })
                        );
                        console.log("came here check 542");
                        history.push(
                          `/categories/${slug}/product/${val.product_name
                            .trim()
                            .replace(/ /g, "_")
                            .replace(/\//g, "#")}`
                        );
                      }}
                      style={{
                        cursor: "pointer"
                      }}
                    >
                      <div className="">
                        <img
                          className="card-img-top"
                          src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.product_image}`}
                          alt="Card cap"
                          width="100%"
                        />
                      </div>
                      <div className="prdsize">
                        <p>{val.size}</p>
                      </div>
                      <div className="card-body  text-center img_txt">
                        <h5 className="card-title">{val.product_name}</h5>
                        <h5>{val.parent_category_name}</h5>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {!history.location.pathname.includes(
                      `/${slug}/${brand}`
                    ) ? (
                      <>
                        {product.map((val, ind) => {
                          return (
                            // val.show_on_home_page === 1 ?
                            <>
                              <div
                                className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                                key={ind}
                              >
                                <div
                                  className="card "
                                  onClick={() => {
                                    val.name === "ENVELOPES"
                                      ? dispatch(setStep(1 + 1.5))
                                      : val.name === "FLYERS"
                                      ? dispatch(setStep(1 + 1.6))
                                      : dispatch(setStep(step + 1));
                                    dispatch(
                                      setIpData({
                                        ...ipData,
                                        stepName: "Product Selected",
                                        completedStep: 0 + 1,
                                        product_id: val.id,
                                        category_id: val.category_id
                                      })
                                    );

                                    // setStep(step + 1);
                                    dispatch(
                                      setPData({
                                        size: val.size,
                                        pname: val.product_name,
                                        pquantity: val.product_quantity,
                                        category: val.name,
                                        description: val.description,
                                        SKU: val.SKU
                                      })
                                    );
                                    dispatch(
                                      setAllData({
                                        ...allData,
                                        cant_find_your_size: "false",
                                        category: val.name,
                                        size: val.size
                                      })
                                    );

                                    // dispatch({ type: "image" });
                                    // dispatch(image(val?.product_image));
                                    // dispatch(currentRoute(val.product_name));

                                    dispatch(
                                      image({
                                        tabName: val.product_name,
                                        image_src: val?.product_image,
                                        isPrinting: checkLoaction || false
                                      })
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer"
                                  }}
                                >
                                  <div className="">
                                    <img
                                      className="card-img-top"
                                      src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.product_image}`}
                                      alt="Card cap"
                                      width="100%"
                                    />
                                  </div>
                                  <div className="prdsize">
                                    <p>{val.size}</p>
                                  </div>
                                  <div className="card-body  text-center img_txt">
                                    <h5 className="card-title">
                                      {val.product_name}
                                    </h5>
                                    <h5>{val.parent_category_name}</h5>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                          // : null;
                        })}
                      </>
                    ) : (
                      <>
                        {console.log("p brand check 697", productBrand)};
                        {productBrand.map((val, ind) => {
                          return (
                            <>
                              <div
                                className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                                key={ind}
                              >
                                <div
                                  className="card"
                                  onClick={() => {
                                    val.name === "ENVELOPES"
                                      ? dispatch(setStep(1 + 1.5))
                                      : val.name === "FLYERS"
                                      ? dispatch(setStep(1 + 1.6))
                                      : dispatch(setStep(step + 1));
                                    dispatch(
                                      setIpData({
                                        ...ipData,
                                        stepName: "Product Selected",
                                        completedStep: 0 + 1,
                                        product_id: val.id,
                                        category_id: val.category_id
                                      })
                                    );

                                    dispatch(
                                      setPData({
                                        size: val.size,
                                        pname: val.product_name,
                                        pquantity: val.product_quantity,
                                        category: val.name,
                                        description: val.description
                                      })
                                    );
                                    // setStep(step + 1);
                                    dispatch(
                                      setAllData({
                                        ...allData,
                                        cant_find_your_size: "false",
                                        category: val.name,
                                        size: val.size
                                      })
                                    );

                                    // dispatch(image(val?.brandimg));
                                    // dispatch(currentRoute(val.product_name));

                                    dispatch(
                                      image({
                                        tabName: val.product_name,
                                        image_src: val?.product_image,
                                        isPrinting: checkLoaction || false
                                      })
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer"
                                  }}
                                >
                                  <img
                                    className="card-img-top"
                                    src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.brandimg}`}
                                    alt="Card cap"
                                    width="280px"
                                    height="90%"
                                  />
                                  <div className="prdsize">
                                    <p>{val.size}</p>
                                  </div>
                                  <div className="card-body  text-center img_txt">
                                    <h5 className="card-title">
                                      {val.product_name}
                                    </h5>
                                    <h5>{val.parent_category_name}</h5>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                  </>
                );
              })
            ) : (
              <>
                {!history.location.pathname.includes(`/${slug}/${brand}`) ? (
                  <>
                    {console.log("product check 797", product)}
                    {product.map((val, ind) => {
                      return (
                        //val.show_on_home_page === 1 ?
                        <div
                          className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                          key={"d" + ind}
                        >
                          <div
                            className="card "
                            onClick={() => {
                              dispatch(
                                setIpData({
                                  ...ipData,
                                  stepName: "Product Selected",
                                  completedStep: 0 + 1,
                                  product_id: val.id,
                                  category_id: val.category_id
                                })
                              );

                              val.name === "ENVELOPES"
                                ? dispatch(setStep(1 + 1.5))
                                : val.name === "FLYERS"
                                ? dispatch(setStep(1 + 1.6))
                                : dispatch(setStep(step + 1));
                              dispatch(
                                setPData({
                                  size: val.size,
                                  pname: val.product_name,
                                  pid: val.id,
                                  pquantity: val.product_quantity,
                                  category: val.name,
                                  description: val.description,
                                  SKU: val.SKU
                                })
                              );
                              dispatch(
                                setAllData({
                                  ...allData,
                                  cant_find_your_size: "false",
                                  category: val.name,
                                  size: val.size
                                })
                              );
                              // setStep(step + 1);
                              {
                                !history.location.pathname.includes(
                                  "/printing-products"
                                )
                                  ? history.push(
                                      `/categories/${slug}/product/${val.product_name
                                        .trim()
                                        .replace(/ /g, "_")
                                        .replace(/\//g, "#")}`
                                    )
                                  : history.push(
                                      `/printing-products/${slug}/product/${val.product_name
                                        .trim()
                                        .replace(/ /g, "_")
                                        .replace(/\//g, "#")}`
                                    );
                              }

                              // dispatch({ type: "image" });
                              // dispatch(image(val?.product_image));
                              // dispatch(currentRoute(val.product_name));

                              dispatch(
                                image({
                                  tabName: val.product_name,
                                  image_src: val?.product_image,
                                  isPrinting: checkLoaction || false
                                })
                              );
                            }}
                            style={{
                              cursor: "pointer"
                            }}
                          >
                            <div className="">
                              <img
                                className="card-img-top"
                                src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.product_image}`}
                                alt="Card cap"
                                width="100%"
                              />
                            </div>
                            <div className="prdsize">
                              <p>{val.size}</p>
                            </div>
                            <div className="card-body  text-center img_txt">
                              <h5 className="card-title">{val.product_name}</h5>
                              <h5>{val.parent_category_name}</h5>
                            </div>
                          </div>
                        </div>
                      );
                      // : null;
                    })}
                  </>
                ) : (
                  <>
                    {productBrand.map((val, ind) => {
                      return (
                        <>
                          <div
                            className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                            key={ind}
                          >
                            <div
                              className="card"
                              onClick={() => {
                                setIPData({
                                  ...ipData,
                                  stepName: "Product Selected",
                                  completedStep: 0 + 1,
                                  product_id: val.id,
                                  category_id: val.category_id
                                });

                                val.name === "ENVELOPES"
                                  ? dispatch(setStep(1 + 1.5))
                                  : val.name === "FLYERS"
                                  ? dispatch(setStep(1 + 1.6))
                                  : dispatch(setStep(step + 1));
                                dispatch(
                                  setPData({
                                    size: val.size,
                                    pname: val.product_name,
                                    pquantity: val.product_quantity,
                                    category: val.name,
                                    description: val.description
                                  })
                                );
                                // setStep(step + 1);
                                dispatch(
                                  setAllData({
                                    ...allData,
                                    cant_find_your_size: "false",
                                    category: val.name,
                                    size: val.size
                                  })
                                );

                                // dispatch(image(val?.brandimg));
                                // dispatch(currentRoute(val.product_name));

                                dispatch(
                                  image({
                                    tabName: val.product_name,
                                    image_src: val?.product_image,
                                    isPrinting: checkLoaction || false
                                  })
                                );

                                // dispatch({ type: "image" });
                                // image(val.product_image);
                              }}
                              style={{
                                cursor: "pointer"
                              }}
                            >
                              <img
                                className="card-img-top"
                                src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.brandimg}`}
                                alt="Card cap"
                                width="280px"
                                height="90%"
                              />
                              <div className="prdsize">
                                <p>{val.size}</p>
                              </div>
                              <div className="card-body  text-center img_txt">
                                <h5 className="card-title">
                                  {val.product_name}
                                </h5>
                                <h5>{val.parent_category_name}</h5>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {history.location.pathname.includes(`${slug}`) &&
        !history.location.pathname.includes("size") ? (
          <div className="">
            <span className="cateogoryDescp">
              <div
                dangerouslySetInnerHTML={{
                  __html: product[0]?.description
                }}
                className="notes-text"
              />
            </span>
          </div>
        ) : null}

        {(!history.location.pathname.includes("/size") &&
          !history.location.pathname.split("/")[
            history.location.pathname.split("/").length - 1
          ] === "printing-products") ||
        !history.location.pathname.split("/")[
          history.location.pathname.split("/").length - 1
        ] === "" ? (
          <div className="row ">
            <div className="col-12">
              <h4
                style={{
                  color: "#444444",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  fontSize: "17px",
                  margin: "35px 0px 53px 0px"
                }}
              >
                Can't find your size ?{" "}
                <Link
                  to="/categories/size"
                  style={{
                    color: "#67AB49",
                    textDecoration: "underline"
                  }}
                >
                  Click here
                </Link>
              </h4>
            </div>
          </div>
        ) : null}
      </>
    </div>
  );
}

const mapStateToProps = state => {
  console.log("check state 933", state);
  return {
    ...state.themeChanger,
    token: state.auth.accessToken, //!= null? state.auth.accessToken : state.auth.token,
    user: state.auth.user,
    isFetching: state.navigation.isFetching,
    inquiry: state.inquiry
  };
};
// const mapDispatchToProps = () => {};

export default compose(
  withRouter,
  // enhancer,
  connect(
    mapStateToProps,
    { success, error, fetching, scroll }
    // mapDispatchToProps
  )
)(Content);
