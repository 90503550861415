import React, { useEffect, useState } from "react";
import Viewer from "react-viewer";
import { getGallery, getGallerys } from "services/galleryService";
import NavigationActions from "redux/navigation/actions";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { getCategories } from "services/categoryServices";
import { Helmet } from "react-helmet";
const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

function Gallery(props) {
  const { token, success, error } = props;

  const [parentcate, setparentcate] = useState([]);
  const [catvalue, setcatvalue] = useState("SHOW ALL");
  const [photos, setphotos] = useState([]);
  const [data, setdata] = useState([]);
  const [array, setArray] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [imgIndex, setImgIndex] = useState(0);

  const [cate, setcat] = useState([]);
  const [visible, setVisible] = useState(false);

  const getcategarise = async () => {
    await getCategories(token).then(data => {
      if (catvalue === "") {
        // const ans = data.data?.filter((val) => {
        //   return val.parent_id !== 0;
        // });
        // setcatvalue(ans[0]?.name);
      }
      setparentcate(data.data);
      if (data.success) {
        success();
      } else {
        error();
      }
    });

    await getGallerys(token).then(data => {
      if (data.success) {
        setAllImages(data.data);
        const photoarray = data.data.map(val => {
          return {
            src: `${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val.product_Images}`,
            alt: `${val.product_Images}`
          };
        });
        setArray(photoarray);
        success();
      } else {
        error();
      }
    });
  };

  useEffect(() => {
    getcategarise();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    await getGallery(token, { data: catvalue }).then(data => {
      if (data.success) {
        setphotos(data.data);
        const photoarray = data.data.map(val => {
          return {
            src: `${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val.product_Images}`,
            alt: `${val.product_Images}`
          };
        });
        setdata(photoarray);
        // success();
      } else {
        // error();
      }
    });
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [catvalue]);
  return (
    <>
      <Helmet>
        <title>Our Gallery – Green Magnets NZ</title>
        <meta name="robots" content="max-image-preview:large"></meta>
      </Helmet>
      <div className="row">
        <div className="col-12">
          <select
            className="sstyle"
            onChange={e => {
              setcatvalue(e.target.value);
              setcat(e.target.value.replaceAll(" ", "-"));
            }}
          >
            <option className="">SHOW ALL</option>
            {parentcate.map((val, key) => {
              return (
                <option
                  key={key}
                  className={
                    val.parent_id === 0 ? "text-bold disabled_color" : ""
                  }
                  disabled={val.parent_id === 0 ? true : null}
                >
                  {val.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {catvalue !== "SHOW ALL" ? (
        <div className="row my-4">
          <div className="col-lg-6 col-md-6  col-sm-6 col-6">
            <h3 className="h3style">{catvalue}</h3>
          </div>

          <div className="col-lg-6 col-md-6  col-sm-6 col-6">
            <Link to={`/categories/${cate}`} className="btn gallery-btn">
              Get a Price
            </Link>
          </div>
        </div>
      ) : null}

      <div className="">
        {/* eslint-disable-next-line */}
        {catvalue == "SHOW ALL" ? (
          parentcate.map((val, key) => {
            return val.parent_id !== 0 ? (
              <>
                <div className="row my-4">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <h3 className="h3style" key={key}>
                      {val.name}
                    </h3>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <Link
                      key={key}
                      to={`/categories/${val.name
                        .replaceAll(" ", "-")
                        .toLowerCase()}`}
                      className="btn gallery-btn"
                    >
                      Get a Price
                    </Link>
                  </div>
                </div>
                <div className="row">
                  {allImages.map((val1, Index) => {
                    return val?.name === val1?.category_name ? (
                      <>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="">
                            <img
                              onClick={() => {
                                setImgIndex(Index);
                                setVisible(true);
                              }}
                              src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val1.product_Images}`}
                              alt={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val1.product_Images}`}
                              className="myimgstyle"
                            />
                          </div>
                        </div>
                      </>
                    ) : null;
                  })}
                </div>
              </>
            ) : null;
          })
        ) : (
          <div className="row">
            {photos?.map((val, ind) => {
              return (
                <>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="">
                      <img
                        onClick={() => {
                          setImgIndex(ind);
                          setVisible(true);
                        }}
                        src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val.product_Images}`}
                        alt={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val.product_Images}`}
                        className="myimgstyle"
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-8 mx-auto">
          <Viewer
            visible={visible}
            activeIndex={imgIndex}
            onClose={() => {
              setVisible(false);
            }}
            rotatable={false}
            images={
              // eslint-disable-next-line
              catvalue == "SHOW ALL" ? array : data
            }
            scalable={false}
            drag={false}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching,
    image: state.productimage.image_src
  };
};

export default compose(
  withRouter,
  // enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(Gallery);
